<template>
  <div class="main-card card pa-0 ma-0 transparent">
    <div class="card-body pa-0">
      <div :class="[this.$vuetify.theme.dark? 'dark-bg' : '']" class="card-title card-header-tab card-header">

        <div class="card-header-title font-size-lg text-capitalize font-weight-normal">
          {{ this.$t('tablebee.lang_service_status') }}
        </div>
        <div class="btn-actions-pane-right actions-icon-btn">

          <div class="btn-actions-pane-right actions-icon-btn">

            <v-btn :disabled="loading || this.loadData" depressed :loading="loading" @click="update"
                   class="elevation-0"
                   color="success">
              {{ this.$t('generic.lang_save') }}
            </v-btn>
          </div>
        </div>
      </div>


      <v-card>
        <v-card-text>
          <v-row>
            <v-col cols="6">
              <v-switch :loading="loading" v-model="form.is_online" :label="this.$t('tablebee.lang_service_online')"
                        :value="true"/>
            </v-col>

            <v-col v-if="!form.is_online" cols="12">
              <label class="text-muted">{{ this.$t('tablebee.lang_offline_message') }}</label>
              <quill-editor :data-layout="KEYBOARD.KEYSETS.NORMAL"
                            :options="quillOptions"
                            @focus="showTouchKeyboard"
                            output="html" class="mb-4" v-model="form.offline_message">

              </quill-editor>
            </v-col>

          </v-row>
        </v-card-text>
      </v-card>
    </div>

    <div id="onScreenKeyboardDiv" v-click-outside="dismissOnScreenKeyboard">
      <vue-touch-keyboard :accept="hideTouchKeyboard" :cancel="hideTouchKeyboard"
                          :defaultKeySet="touchKeyboard.keySet"
                          :input="touchKeyboard.input" :layout="touchKeyboard.layout"
                          :options="touchKeyboard.options" class="internalWidth"
                          id="onScreenKeyboard" v-if="touchKeyboard.visible"/>
    </div>
  </div>

</template>


<script>


import {ENDPOINTS} from "@/config";
import {Events} from "@/plugins/events";
import mixin from "../../../mixins/KeyboardMixIns";
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'
import {quillEditor} from 'vue-quill-editor';

export default {
  name: "tableBeeStatusComponent",
  mixins: [mixin],
  components: {
    quillEditor
  },
  data() {
    return {
      loading: false,
      loadData: false,
      quillOptions: {

        modules: {
          toolbar: [
            ['bold', 'italic', 'underline', 'strike'],
            [{'header': [1, 2, 3, 4, 5, 6, false]}],
            [{'align': []}],
            [{'font': []}],
            [{'list': 'ordered'}, {'list': 'bullet'}, {'indent': '-1'}, {'indent': '+1'}],
          ],
        },
      },
      form: {
        is_online: false,
        offline_message: ""
      }
    }
  },
  methods: {
    update() {
      this.loading = true;
      this.axios.post(ENDPOINTS.TABLEBEE.SETTINGS.STATSUS.UPDATE, this.form).then(res => {
        if (res.data.status === "SUCCESS") {
          Events.$emit("showSnackbar", {
            message: this.$t('generic.lang_dataSaved'),
            color: "success"
          })
        } else {
          Events.$emit("showSnackbar", {
            message: res.data.msg || res.data.status,
            color: "error"
          })
        }
      }).catch(err => {
        Events.$emit("showSnackbar", {
          message: err.message,
          color: "error"
        })
      }).finally(() => {
        this.loading = false;
      });
    },
    loadStatuses() {
      this.loading = true;
      this.axios.post(ENDPOINTS.TABLEBEE.SETTINGS.STATSUS.GET).then(res => {
        if (res.data.STATUS === "SUCCESS") {
          this.form.is_online = res.data.data.is_online;
          this.form.offline_message = res.data.data.offline_message;
        } else {
          Events.$emit("showSnackbar", {
            message: err.message,
            color: "error"
          })
        }
      }).catch(err => {
        Events.$emit("showSnackbar", {
          message: err.message,
          color: "error"
        })
      }).finally(() => {
        this.loading = false;
      });
    }
  },
  mounted() {
    this.loadStatuses();
  }
}
</script>

<style scoped>

</style>
