<template>
  <v-container fluid>

    <v-row>
      <!--menu-->
      <v-col cols="12" sm="12" md="3">
        <b-card :class="[this.$vuetify.theme.dark? 'dark-bg' : '', 'mb-1']" no-body>
          <b-card-header class="p-0" header-tag="header" role="tab">
            <v-list-item @click="opened =! opened" class="ma-0"
                         v-b-toggle.global-settings>
              <v-list-item-title :class="this.opened? 'primary--text text-wrap' : 'text-wrap'">
                {{ this.$t('settings.lang_settings') }}
              </v-list-item-title>
              <v-list-item-action>
                <v-icon v-if="!this.opened">keyboard_arrow_down</v-icon>
                <v-icon v-if="this.opened">keyboard_arrow_up</v-icon>
              </v-list-item-action>
            </v-list-item>
          </b-card-header>
          <b-collapse :visible="this.opened" accordion="global-settings" id="global-settings"
                      role="tabpanel">
            <b-card-body class="pa-0">
              <b-list-group flush>
                <b-list-group-item :class="[this.$vuetify.theme.dark? 'dark-bg' : '', this.currentTab === 'service_status'? 'primary--text' : '']"
                                   @click="handleClick('service_status')"
                                   class="items">
                  {{ this.$t('tablebee.lang_service_status') }}
                </b-list-group-item>

                <b-list-group-item :class="[this.$vuetify.theme.dark? 'dark-bg' : '', this.currentTab === 'service_time'? 'primary--text' : '']"
                                   @click="handleClick('service_time')"
                                   class="items">
                  {{ this.$t('tablebee.lang_service_times') }}
                </b-list-group-item>

                <b-list-group-item :class="[this.$vuetify.theme.dark? 'dark-bg' : '', this.currentTab === 'payment'? 'primary--text' : '']"
                                   @click="handleClick('payment')"
                                   class="items">
                  {{ this.$t('generic.lang_zahlungsoptionen') }}
                </b-list-group-item>

                <b-list-group-item :class="[this.$vuetify.theme.dark? 'dark-bg' : '', this.currentTab === 'autoPrint'? 'primary--text' : '']"
                                   @click="handleClick('autoPrint')"
                                   class="items">
                  {{  this.$t('settings.lang_printer')+' '+this.$t('settings.lang_settings') }}
                </b-list-group-item>

                <b-list-group-item :class="[this.$vuetify.theme.dark? 'dark-bg' : '', this.currentTab === 'settings'? 'primary--text' : '']"
                                   @click="handleClick('settings')"
                                   class="items">
                  {{ this.$t('settings.lang_settings') }}
                </b-list-group-item>

                <b-list-group-item :class="[this.$vuetify.theme.dark? 'dark-bg' : '', this.currentTab === 'help'? 'primary--text' : '']"
                                   @click="handleClick('help')"
                                   class="items">
                  {{ this.$t('generic.lang_nav_help') }}
                </b-list-group-item>
              </b-list-group>
            </b-card-body>
          </b-collapse>
        </b-card>

      </v-col>


      <!--content-->
      <v-col class="py-0" cols="12" sm="12" md="9">
        <v-card>
          <v-card-text class="ma-0 pa-0">
            <v-container class="ma-0 pa-0">
              <v-row no-gutters>
                <v-col>
                  <template>
                    <div role="tablist">

                      <table-bee-status-component v-if="currentTab === 'service_status'"/>

                      <table-bee-service-times-component v-if="currentTab === 'service_time'"/>

                      <table-bee-payment-option-component v-if="currentTab === 'payment'"/>

                      <table-bee-other-settings-component v-if="currentTab === 'settings'"/>

                      <table-bee-auto-printing-settings-component v-if="currentTab === 'autoPrint'" />

                      <table-bee-settings-help-component v-if="this.currentTab === 'help'"/>
                    </div>
                  </template>
                </v-col>

              </v-row>
            </v-container>
          </v-card-text>
        </v-card>
      </v-col>

    </v-row>

  </v-container>


</template>

<script>
import TableBeeServiceTimesComponent from "@/components/tableBee/settings/tableBeeServiceTimesComponent";
import TableBeePaymentOptionComponent from "@/components/tableBee/settings/tableBeePaymentOptionComponent";
import TableBeeStatusComponent from "@/components/tableBee/settings/tableBeeStatusComponent";
import TableBeeOtherSettingsComponent from "./tableBeeOtherSettingsComponent";
import TableBeeAutoPrintingSettingsComponent from "./tableBeeAutoPrintingSettingsComponent";
import TableBeeSettingsHelpComponent from "./tableBeeSettingsHelpComponent";

export default {
  name: "tableBeeSettingComponent",
  components: {
    TableBeeAutoPrintingSettingsComponent,
    TableBeeOtherSettingsComponent,
    TableBeeSettingsHelpComponent,
    TableBeeStatusComponent, TableBeePaymentOptionComponent, TableBeeServiceTimesComponent
  },
  data() {
    return {
      opened: true,
      currentTab: "service_status"
    }
  },
  methods: {
    handleClick(newTab) {
      if (newTab && newTab.length > 0) {
        this.currentTab = newTab;
        this.$router.push({query: {tab: newTab}})
      }
    },
  },
  mounted() {
    this.handleClick(this.$route.query.tab)
  }
}
</script>

<style scoped>
.items {
  cursor: pointer;
  user-select: none;
}
</style>
