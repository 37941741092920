<template>
  <v-card :loading="loading">
    <v-card-title> {{  this.$t('settings.lang_printer')+' '+this.$t('settings.lang_settings') }} </v-card-title>
    <v-divider class="ma-0"/>
    <v-card-text>
      <v-container fluid>
        <v-row>
          <v-col cols="12" sm="6" md="6" lg="6">
            <v-switch :label="$t('settings.lang_activateAutoPrint')" v-model="enableAutoPrinting " />
          </v-col>
          <v-col cols="12" sm="6" md="6" lg="6">
            <v-switch :label="$t('settings.lang_autoPrintHybrid')" v-model="autoPrintOnlyInHybrid" />
          </v-col>
          <v-col cols="12" sm="6" md="6" lg="6">
            <v-select
                v-model="autoPrintingCashierIDs"
                :items="filteredCashierIDs" item-text="text" item-value="value"
                :label="$t('generic.lang_cashierID')"
                multiple
                outlined
            ></v-select>
          </v-col>
          <v-col cols="12" sm="6" md="6" lg="6">
            <v-select
                v-model="autoPrintingUsers"
                :items="users" item-text="fullname" item-value="user_id"
                :label="$t('settings.lang_users')"
                multiple
                outlined
            ></v-select>
          </v-col>
        </v-row>
      </v-container>
    </v-card-text>
    <v-divider class="ma-0"/>
    <v-card-actions class="pr-6">
      <v-row no-gutters>
        <v-col cols="12" align="end">
          <v-btn large :loading="loading" @click="update" color="success">
            {{ $t("generic.lang_save") }}
          </v-btn>
        </v-col>
      </v-row>
    </v-card-actions>
  </v-card>
</template>

<script>
import {ENDPOINTS} from "../../../config";
import {Events} from "@/plugins/events";
export default {
  name: "tableBeeAutoPrintingSettingsComponent",
  data(){
    return{
      loading:false,
      enableAutoPrinting:false,
      autoPrintOnlyInHybrid:false,
      autoPrintingCashierIDs:[],
      cashierIDs:[],
      users: [],
      autoPrintingUsers: [],
    }
  },
  computed:{
    filteredCashierIDs() {
      return this.cashierIDs.map((cashierID) => {
        return {
          value: parseInt(cashierID),
          text: this.$t('generic.lang_kasse') + " " + cashierID
        };
      });
    }
  },
  methods:{
    loadUsers() {
      this.axios.post(ENDPOINTS.SETTINGS.USER.GETALL).then((res) => {
        if (res.data.STATUS === "SUCCESS") {
          this.users = res.data.users;
        } else {
          Events.$emit("showSnackbar", {
            message: this.$t('generic.lang_errorOccurred'),
            color: "error"
          });
        }
      }).catch((err) => {
        Events.$emit("showSnackbar", {
          message: err.message,
          color: "error"
        });
      })
    },
    getData() {
      this.loading = true;
      this.axios
          .post(ENDPOINTS.TABLEBEE.SETTINGS.AUTOPRINTSETTINGS.GET)
          .then((res) => {
            if(res.data.STATUS==="SUCCESS"){
              this.enableAutoPrinting=res.data.data.enableAutoPrinting;
              this.autoPrintOnlyInHybrid=res.data.data.autoPrintOnlyInHybrid;
              this.autoPrintingCashierIDs=res.data.data.autoPrintingCashierIDs;
              this.autoPrintingUsers=res.data.data.autoPrintingUsers;
            }
          })
          .catch((err) => {
            Events.$emit("showSnackbar", {
              color: "error",
              message: err.message,
            });
          })
          .finally((fin) => {
            this.loading = false;
          });
    },
    update() {
      this.loading = true;
      this.axios
          .post(ENDPOINTS.TABLEBEE.SETTINGS.AUTOPRINTSETTINGS.UPDATE, {
            enableAutoPrinting:this.enableAutoPrinting,
            autoPrintOnlyInHybrid:this.autoPrintOnlyInHybrid,
            autoPrintingCashierIDs:this.autoPrintingCashierIDs,
            autoPrintingUsers:this.autoPrintingUsers,
          })
          .then((res) => {
            if (res.data.status === "SUCCESS") {
              Events.$emit("showSnackbar", {
                color: "success",
                message: this.$t('generic.lang_dataSaved'),
              });
            } else {
              Events.$emit("showSnackbar", {
                color: "error",
                message: res.data.msg || res.data.status,
              });
            }
          })
          .catch((err) => {
            Events.$emit("showSnackbar", {
              color: "error",
              message: err.message,
            });
          })
          .finally((fin) => {
            this.loading = false;
          });
    },
    getCashiers() {
      this.loading = true;

      this.axios.post(ENDPOINTS.GENERIC.CASHIERIDS.GET).then((res) => {
        this.cashierIDs = res.data.cashierIDs;
      }).finally(() => this.loading = false);
    },
  },
  mounted(){
    this.getCashiers();
    this.getData();
    this.loadUsers();
  }
}
</script>

<style scoped>

</style>