<template>
  <v-card class="transparent elevation-0">
    <v-card-title
      :class="[this.$vuetify.theme.dark ? 'dark-bg' : '']"
      class="card-header"
    >
      {{ this.$t("generic.lang_nav_help") }}
    </v-card-title>

    <v-card-text>
      <div class="my-3">
        <!-- here you can add text -->
      </div>

      <div>
        <a href="https://hilfe.3pos.de/docs-category/tablebee/" target="_blank">
          https://hilfe.3pos.de/docs-category/tablebee/
        </a>
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  name: "tableBeeSettingsHelpComponent",
};
</script>
